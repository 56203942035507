.cascading-right {
    margin-right: -50px;
}

@media (max-width: 900px) {
    .cascading-right {
        margin-right: 0;
    }
    .image-column {
        display: none;
    }
}

.image-column img {
    max-width: 100%;
    height: auto;
    display: block;
}
